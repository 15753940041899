export const currencyFormatter = ({
  amount,
  currency = "USD",
}: {
  amount: number | undefined;
  currency?: string;
}) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  }).format(amount ?? 0);
};

export const getVideoType = (url: string | undefined | null) => {
  if (!url || typeof url !== "string") return "video/mp4";

  const extension = url.split(".").pop()?.split("?")[0]?.toLowerCase();

  const videoTypes = {
    mp4: "video/mp4",
    webm: "video/webm",
    ogg: "video/ogg",
    mov: "video/quicktime",
    m4v: "video/mp4",
  };

  return videoTypes[extension as keyof typeof videoTypes] || "video/mp4";
};
