// eslint-disable-next-line no-undef
export const apiPath = process.env.REACT_APP_API_PATH;

export const QUESTIONNAIRE_LINK = process.env.REACT_APP_QUESTIONNAIRE_LINK;

export const GOOGLE_TAG_MANAGER_ID =
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const areasWeProvideSupport = [
  "Aggression & Tantrums",
  "Anxiety and Depression",
  "Attention, Focus, & ADHD",
  "Autism Spectrum Disorders",
  "Behavioral Issues",
  "Communication & Speech Delays",
  "Developmental Delays",
  "Emotional Regulation",
  "Parenting Guidance & Support",
  "Feeding, Sleep, and Toileting",
  "Social Interaction Difficulties",
];

export const appNumuwGetStartedUrl =
  "https://app.numuw.com/signup/client?lng=en";

export const routes = {
  school: "/school",
  talkToAdvisor: "/school/talk-to-advisor",
  talkToAdvisorSuccess: "/school/talk-to-advisor/success",
};

export const externalRoutes = {
  loginApp: "https://app.numuw.com/login",
};

export const statusColor = {
  error: { backgroundColor: "#FFE4E4", color: "#E51616" },
  success: { backgroundColor: "#F8FFEB", color: "#3B8400" },
  warning: { backgroundColor: "#FFFBE4", color: "#B19607" },
  info: {
    backgroundColor: "#E0F2FE",
    color: "#0C4A6E",
  },
  default: { backgroundColor: "#F6F0FC", color: "#8450a0" },
};
