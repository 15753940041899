import { Pagination as MuiPagination } from "@mui/material";
import styled from "styled-components";
import { theme } from "theme/theme";
import { Box } from "./Core";

const StyledPagination = styled(MuiPagination)`
  .MuiPagination-ul {
    gap: 8px;
  }

  .MuiPaginationItem-root {
    color: ${theme.colors.textPrimary};
    border-radius: 8px;

    &.Mui-selected {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};

      &:hover {
        background-color: ${theme.colors.primary[700]};
      }
    }
  }
`;

interface PaginationProps {
  count: number;
  page: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  size?: "small" | "medium" | "large";
}

export const Pagination = ({
  count,
  page,
  onChange,
  size = "medium",
}: PaginationProps) => {
  return (
    <Box py={4} display="flex" justifyContent="center">
      <StyledPagination
        count={count}
        page={page}
        onChange={onChange}
        size={size}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};
