import { useState, useEffect } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { apiPath } from "constants/index";

interface QueryConfig<T> extends AxiosRequestConfig {
  url: string;
  enabled?: boolean;
  onSuccess?: (data: T) => void;
  onError?: (error: Error) => void;
}

axios.interceptors.request.use(
  (config) => {
    config.baseURL = apiPath;
    return config;
  },
  (error) => Promise.reject(error)
);

export function useQuery<T>({
  url,
  enabled = true,
  onSuccess,
  onError,
  params,
  ...config
}: QueryConfig<T>) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get<T>(url, { ...config, params });
      setData(response.data);
      onSuccess?.(response.data);
    } catch (err) {
      const error = err instanceof Error ? err : new Error("An error occurred");
      setError(error);
      onError?.(error);
    } finally {
      setLoading(false);
    }
  };

  const refetch = () => {
    if (enabled) {
      fetchData();
    }
  };

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [url, enabled, JSON.stringify(params)]);

  return { data, loading, error, refetch };
}

interface MutationConfig<T, V> extends Omit<AxiosRequestConfig, "data"> {
  url: string;
  method?: "POST" | "PUT" | "PATCH" | "DELETE";
  onSuccess?: (data: T) => void;
  onError?: (error: Error) => void;
}

export function useMutation<T, V>({
  url,
  method = "POST",
  onSuccess,
  onError,
  ...config
}: MutationConfig<T, V>) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const mutate = async (variables: V) => {
    try {
      setLoading(true);
      const response = await axios({
        url,
        method,
        data: variables,
        ...config,
      });

      setData(response.data);
      onSuccess?.(response.data);
      return response.data;
    } catch (err) {
      const error = err instanceof Error ? err : new Error("An error occurred");
      setError(error);
      onError?.(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { mutate, data, loading, error };
}
