import {
  Box,
  CenterColumn,
  CenterRow,
  Chip,
  Container,
  Flex,
  Text,
} from "components/Core";
import styled from "styled-components";
import type { Course, Courses as CoursesResponse } from "utils/types/types";
import { motion } from "framer-motion";
import { theme } from "theme/theme";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Grid } from "@mui/material";
import { useState } from "react";
import { useCourseProgress } from "./hook/useCourseProgress";
import { t } from "i18next";
import { useCourseContent } from "./hook/useCourseContent";
import { Pagination } from "components/Pagination";
import { useQuery } from "hooks/query";
import { useLocal } from "hooks/useLocal";

const CourseCard = styled(motion.div)`
  background: ${theme.colors.white};
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  // min-height: 450px;
  box-shadow: ${theme.shadows.md};
`;

const CourseImage = styled.div<{ bg?: string }>`
  height: 200px;
  background: ${({ bg }) =>
    bg
      ? `url(${bg})`
      : `linear-gradient(45deg, ${theme.colors.typePurpure[300]} 30%, ${theme.colors.primary["600"]} 90%)`};
  background-size: cover;
  background-position: center;
  position: relative;
`;

// const CourseContent = styled.div`

// `

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: ${theme.colors.textSecondary};
  margin-right: 8px;
  font-size: inherit;
`;

const DiscountedPrice = styled.span`
  color: ${theme.colors.typePurpure[300]};
  font-weight: bold;
  font-size: inherit;
`;

const AuthorBadge = styled.div`
  background: linear-gradient(
    135deg,
    ${theme.colors.primary[100]},
    ${theme.colors.gray["100"]}
  );
  padding: 12px 20px;
  border-radius: 10px;
  font-weight: 500;
  width: fit-content;
  color: ${theme.colors.typePurpure[300]};
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  // border: 1px solid ${theme.colors.typePurpure[200]};
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TagsContainer = styled(Flex)`
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 12px;
`;

const Tag = styled.span`
  background: ${theme.colors.gray[100]};
  color: ${theme.colors.textPrimary};
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Courses = () => {
  const [search_query, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery<CoursesResponse>({
    url: "/api/course/all/landing/",
    method: "GET",
    params: {
      page: page,
      search_query: search_query,
    },
  });

  const calculateDiscountedPrice = (price: number, discount: number) => {
    return price - (price * discount) / 100;
  };
  const navigate = useNavigate();
  const { getProgress } = useCourseProgress();
  const courseDirection = (courseLanguage: string) => {
    if (courseLanguage === "ar") {
      return "rtl";
    }
    return "ltr";
  };
  const { getContent } = useCourseContent();
  const { direction } = useLocal();
  return (
    <Container flexDirection="column" p={2} py={30} gap={30}>
      <Flex
        direction={direction}
        width={["100%", "100%", "90%"]}
        maxWidth={["1400px"]}
        flexDirection={["column"]}
        gap={16}
        p={[2, 3, "30px"]}
        backgroundColor="white"
        minHeight={"90vh"}
      >
        <Text width="100%" maxWidth="100%" variant="heading3">
          {t("courses")}
        </Text>
        {!loading ? (
          data?.count ? (
            <Grid container spacing={3}>
              {data?.results.map((course: Course) => (
                <Grid key={course.id} item xs={12} lg={6} xl={4}>
                  <Box
                    onClick={() => {
                      navigate(`/course/${course.id}`, {
                        state: {
                          direction: courseDirection(course.language),
                        },
                      });
                    }}
                    // width={['100%', '100%', '400px', '400px']}
                    height={"100%"}
                    direction={courseDirection(course.language)}
                  >
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      whileHover={{ y: -10 }}
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        height: "100%",
                      }}
                    >
                      <CourseCard>
                        <CourseImage
                          bg={course.thumbnail || undefined}
                        ></CourseImage>
                        <Flex
                          gap={8}
                          padding={["20px"]}
                          height="calc(100% - 200px)"
                          flexDirection="column"
                          justifyContent="space-between"
                        >
                          <CenterColumn gap={2}>
                            <Text variant="heading4" mb={2}>
                              {course.title}
                            </Text>
                            <Text
                              lineHeight={1.4}
                              truncateLines={5}
                              color="textSecondary"
                              fontSize="16px"
                              mb={3}
                            >
                              {course.short_description}
                            </Text>
                            <CenterRow width="100%">
                              <AuthorBadge>
                                <Text color={"textLightGray"}>
                                  {getContent(course?.language)?.author}:
                                </Text>
                                {course.author.full_name}
                              </AuthorBadge>
                            </CenterRow>
                          </CenterColumn>
                          <CenterColumn gap={8}>
                            <TagsContainer>
                              <Tag>
                                📚 {course.journey.length}{" "}
                                {getContent(course?.language)?.lessons}
                              </Tag>
                              {Array.from(
                                new Set(course.journey.map((item) => item.type))
                              ).map((type, index) => {
                                const count = course.journey.filter(
                                  (item) => item.type === type
                                ).length;
                                return (
                                  <Tag key={index}>
                                    {type === "video" && "🎥"}
                                    {type === "document" && "📄"}
                                    {type === "mcq" && "❓"}
                                    {getContent(course?.language)?.[type]} (
                                    {count})
                                  </Tag>
                                );
                              })}
                            </TagsContainer>

                            <CenterRow
                              my={2}
                              width="100%"
                              justifyContent={
                                getProgress(course?.purchased_course?.journey)
                                  ? "space-between"
                                  : "flex-end"
                              }
                            >
                              {getProgress(
                                course?.purchased_course?.journey
                              ) ? (
                                <Chip
                                  value={`${
                                    getContent(course.language).progress
                                  } ${getProgress(
                                    course?.purchased_course?.journey
                                  )}%`}
                                />
                              ) : null}
                              {!course.purchased ? (
                                <CenterRow
                                  fontSize={[14, 15, 16, 20]}
                                  padding=" 8px 15px"
                                  borderRadius="20px"
                                >
                                  {course.price === 0 ? (
                                    ""
                                  ) : (
                                    <CenterRow gap={1}>
                                      {course.discount > 0 && (
                                        <OriginalPrice>
                                          ${course.price}
                                        </OriginalPrice>
                                      )}
                                      <DiscountedPrice>
                                        $
                                        {course.discount > 0
                                          ? calculateDiscountedPrice(
                                              course.price,
                                              course.discount
                                            ).toFixed(2)
                                          : course.price}
                                      </DiscountedPrice>
                                    </CenterRow>
                                  )}
                                </CenterRow>
                              ) : (
                                <Chip
                                  value={
                                    getContent(course?.language)?.purchased
                                  }
                                  type="success"
                                />
                              )}
                            </CenterRow>
                          </CenterColumn>
                        </Flex>
                      </CourseCard>
                    </motion.div>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Container
              flexDirection="column"
              flexGrow={1}
              gap={20}
              alignItems={["center"]}
            >
              <Box width={100} height={100}>
                <img
                  width={"100%"}
                  height={"100%"}
                  src="https://numuw-prod-public.s3.me-central-1.amazonaws.com/email_imgs/8.png"
                  alt="empty goals"
                />
              </Box>
              <CenterColumn alignItems="center" gap="10px">
                <Text
                  color="textLightGray"
                  textAlign="center"
                  variant="heading4"
                >
                  {t("course:no_courses")}
                </Text>
              </CenterColumn>
            </Container>
          )
        ) : (
          <Container flexGrow={1}>
            <CircularProgress />
          </Container>
        )}
        <Pagination
          count={Math.ceil((data?.count || 0) / 10)}
          page={page}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
        />
      </Flex>
    </Container>
  );
};
