import { useCallback, useMemo } from "react";

import { DirectionType } from "components/Core/common/types";
import { courseArabic } from "../locale/course_arabic";
import { courseEnglish } from "../locale/course_english";

export const useCourseContent = (language?: string | undefined) => {
  const courseContent = useMemo(() => {
    if (language === "ar")
      return { content: courseArabic, direction: "rtl" as DirectionType };

    return { content: courseEnglish, direction: "ltr" as DirectionType };
  }, [language]);

  const getContent = useCallback((lng: string) => {
    if (lng === "ar") return courseArabic;

    return courseEnglish;
  }, []);

  return { ...courseContent, getContent };
};
