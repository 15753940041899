import { height, variant } from "styled-system";
import { commonComposes } from "../common/commonComposes";
import type { CommonProps } from "../common/types";
import styled from "styled-components";
import type { CSSObject } from "styled-components";
import { type ReactNode, type RefObject, useMemo } from "react";

export interface BoxProps extends CommonProps {
  variant?: "modal-container" | "card";
  _before?: CSSObject | undefined;
  _after?: CSSObject | undefined;
  hideScrollbar?: boolean;
}
export const Box = styled("div")<BoxProps>(
  (props) => ({
    "&:hover": props._hover,
    transition: "all 0.1s ease-in-out",
    ":before": props._before,
    ":after": props._after,
    "::-webkit-scrollbar": {
      display: "none !important",
    },
  }),
  variant({
    variants: {
      "modal-container": {
        background: "white",
        // padding: '24px',
        height: "auto",
        position: "absolute",
        borderRadius: "16px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        outline: "none",
        padding: ["10px", "10px", "10px", "24px"],
        width: ["95%", "95%", "95%", "90%", "809px"],
      },
      card: {
        padding: "16px",
        borderRadius: "10px",
        background: "white",
        boxShadow: "md",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "themed",
        },

        "&:focus": {
          outline: "none",
        },
        "&:focus-visible": {
          outline: "none",
        },
        "&:focus-within": {
          outline: "none",
        },
      },
    },
  }),
  commonComposes()
);

export const Flex = styled(Box)({
  display: "flex",
});

export const CenterRow = styled(Flex)({
  alignItems: "center",
});
export const CenterColumn = styled(Flex)({
  justifyContent: "center",
  flexDirection: "column",
});

export const Container = styled(Flex)`
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
`;
export const ModalContentContainer = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const ModalContent = styled(Box)({
  maxHeight: "90%",
  width: "50%",
  display: "flex",
  justifyContent: "center",
  background: "white",
});

export const ChipComponent = styled(Container)({
  borderRadius: "50px",
  minWidth: "max-content",
  height: "fit-content",
});

export type ChipTypes = "error" | "success" | "warning" | "info" | "default";
interface ChipsProps extends BoxProps {
  value: string | number | undefined | ReactNode;
  type?: ChipTypes;
  ref?: RefObject<HTMLDivElement>;
  onClick?: () => void;
  id?: string;
  rightAdornment?: React.ReactNode;
  leftAdornment?: React.ReactNode;
  textTransform?: "capitalize" | "uppercase" | "lowercase" | "none";
}
export const Chip = ({
  type = "default",
  value,
  rightAdornment,
  textTransform,
  leftAdornment,
  ...rest
}: ChipsProps) => {
  const style = useMemo(() => {
    switch (type) {
      case "info":
        return {
          background: "#E0F2FE",
          color: "#0C4A6E",
        };
      case "success":
        return { background: "#F8FFEB", color: "#3B8400" };

      case "warning":
        return { background: "#FFFBE4", color: "#B19607" };

      case "error":
        return { background: "#FFE4E4", color: "#E51616" };

      default:
        return { background: "#F6F0FC", color: "#8450a0" };
    }
  }, [type]);
  return (
    <ChipComponent
      style={{
        textTransform,
      }}
      minWidth="fit-content"
      backgroundColor={style.background}
      color={style.color}
      padding="8px 16px"
      {...rest}
    >
      {leftAdornment}
      {value}
      {rightAdornment}
    </ChipComponent>
  );
};

interface OverflowProps {
  showScrollbar?: boolean;
}

export const Overflow = styled(Box)<OverflowProps>`
  overflow: auto;

  ${({ showScrollbar }) =>
    !showScrollbar &&
    `
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  `}
`;
