import { FC, useEffect, useMemo, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { VideoContainer } from "./CourseSharedComponents";
import { getVideoType } from "utils/index";

interface VideoPlayerProps {
  link: string;
  poster?: string;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ link, poster }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<any>(null);

  const videoType = useMemo(() => getVideoType(link), [link]);

  useEffect(() => {
    if (!videoRef.current) return;

    const videoOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      fill: true,
      preload: "auto",
      // playbackRates: [0.5, 1, 1.5, 2], // Add playback speed options
      html5: {
        vhs: {
          overrideNative: true,
          enableLowInitialPlaylist: true, // Start with lower quality
          limitRenditionByPlayerDimensions: true,
        },
        nativeAudioTracks: false,
        nativeVideoTracks: false,
      },
      sources: [
        {
          src: link,
          type: videoType,
        },
      ],
      poster,
    };

    playerRef.current = videojs(videoRef.current, videoOptions);

    // Add quality selector plugin
    playerRef.current.qualityLevels();

    // Handle errors with retry logic
    playerRef.current.on("error", () => {
      setTimeout(() => {
        playerRef.current.src(link);
      }, 1000);
    });
  }, [link, videoType]);

  return (
    <VideoContainer>
      <div data-vjs-player>
        <video
          ref={videoRef}
          className="video-js vjs-big-play-centered"
          playsInline // Better mobile performance
        />
      </div>
    </VideoContainer>
  );
};
